import React from 'react'
import classes from './Backdrop.module.css'

export default props =>
  props.show ? (
    <div
      className={classes.Backdrop}
      onClick={props.clicked}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      {props.children}
    </div>
  ) : null
