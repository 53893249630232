import React, { Component } from 'react'
import classes from './Layout.module.css'
import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import Footer from '../../components/Footer/Footer'
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer'
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'

class Layout extends Component {
  state = {
    showSideDrawer: false
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) this.props.onTryAutoSignup()
  }

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false })
  }

  sideDrawerToggleHandler = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer }
    })
  }

  render() {
    return (
      <div className={classes.Container}>
        <div className={classes.Toolbar}>
          <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler} />
        </div>
        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
        />
        <div className={classes.Content}>
          <main>{this.props.children}</main>
        </div>
        <div
          className={
            this.props.landing ? classes.FooterLanding : classes.Footer
          }
        >
          <Footer />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
